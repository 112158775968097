import {list as getPermissions} from "@/api/permission";
const getDefaultState = () => {
    return {
        permissions: [],
    }
};
const state = getDefaultState();
const mutations = {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = Object.freeze([...state.permissions, permissions]);
    }
};
const actions = {
    permissionsServer({commit}){
        return new Promise((resolve, reject) => {
          getPermissions().then(res => {
            if (res != null && res.status == 200) {
              commit('SET_PERMISSIONS', res.data);
              resolve();
            }
          }).catch(err => {
            return reject(err);
          });
        });
      },
    clearPermissions({commit}){
        //state.permissions = [...[]];
        commit('SET_PERMISSIONS', []);
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
  };