import request from '@/utils/request'
function login(data){
    return request({
        url: '/login',
        method: 'post',
        data
    });
}
function register(data){
    return request({
        url: '/register',
        method: 'post',
        data
    });
}
function profile(){
    return request({
        url: '/profile',
        method: 'get'
    });
}
function edit(data){
    return request({
        url: '/profile',
        method: 'post',
        data
    });
}
function verifyEmail(url) {
    return request({
        url: url,
        method: 'get'
    });
}
function upgrade(data){
    return request({
        url: '/profile/upgrade',
        method: 'post',
        data
    });
}
export {login, profile, register, verifyEmail, edit, upgrade};